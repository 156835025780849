import {CoordinateC} from '../utils/geo'
import {OfferC} from './Offer'
import {stringOrUndefined, boolOrUndefined} from '../utils/io-ts-util'
import * as t from 'io-ts'

export const StoreBaseC = t.strict(
  {
    storeBrandKey: stringOrUndefined,
    name: t.string,
    phone: stringOrUndefined,
    address1: t.string,
    address2: stringOrUndefined,
    city: t.string,
    state: t.string,
    zip: t.string,
    logoUri: t.string,
    webUri: stringOrUndefined,
    location: CoordinateC,
    description: stringOrUndefined,
    enabled: boolOrUndefined,
    lastChangedOn: stringOrUndefined,
    lastChangedBy: stringOrUndefined,
  },
  'StoreBase',
)

const WithStoreC = t.strict(
  {
    store: t.union([StoreBaseC, t.null, t.undefined]),
  },
  'OfferWithStore',
)

const PersistedStoreC = t.strict({
  storeLocationKey: t.string,
  // geoLocation: t.union([GeoLocationC, t.undefined, t.null]),
  // source: t.string,
})

const StoreWithOffersC = t.strict({
  offers: t.readonlyArray(OfferC),
})

const StoreWithOffersIdC = t.strict({
  id: t.number,
})

export const OfferWithStoreC = t.intersection(
  [OfferC, WithStoreC],
  'OffersWithStore',
)

export const StoreC = t.intersection([PersistedStoreC, StoreBaseC], 'Stores')

export const StoreOffersC = t.intersection(
  [StoreC, StoreWithOffersC],
  'StoreOffers',
)

export const StoreOffersWithIdC = t.intersection(
  [StoreOffersC, StoreWithOffersIdC],
  'StoreOffersWithIdC',
)

export type OfferWithStore = t.TypeOf<typeof OfferWithStoreC>

export type StoreBase = t.TypeOf<typeof StoreBaseC>

export type Store = t.TypeOf<typeof StoreC>

export type StoreOffers = t.TypeOf<typeof StoreOffersC>

export type StoreOffersWithId = t.TypeOf<typeof StoreOffersWithIdC>
