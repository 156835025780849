import {numberOrUndefined, stringOrUndefined} from '../utils/io-ts-util'
import * as t from 'io-ts'

export const VertOnlineCategoryC = t.union([
  t.literal('Clothing'),
  t.literal('Health & Beauty'),
  t.literal('Electronics'),
  t.literal('Sports, Outdoors & Fitness'),
  t.literal('Food and Drinks'),
  t.literal('Shoes'),
  t.literal('Accessories'),
  t.literal('Gifts & Flowers'),
  t.literal('Pets'),
  t.literal('Toys & Games'),
  t.literal('Auto & Tires'),
  t.literal('Office supplies'),
  t.literal('Mail Subscriptions'),
  t.literal('Digital Services & Streaming'),
  t.literal('Home and Garden'),
  t.literal('Events & Entertainment'),
])
export type VertOnlineCategory = t.TypeOf<typeof VertOnlineCategoryC>

export const VertOnlineOfferC = t.strict(
  {
    vertOnlineCats: t.readonlyArray(VertOnlineCategoryC),
    storeName: t.string,
    phone: stringOrUndefined,
    logoUri: t.string,
    webUri: stringOrUndefined,
    description: stringOrUndefined,
    promoCode: stringOrUndefined,
    title: t.string,
    termsOfUse: t.string,
    enabled: t.boolean,
    redemptionUri: t.string,
    priority: numberOrUndefined,
    lastChangedOn: stringOrUndefined,
    lastChangedBy: stringOrUndefined,
  },
  'VertOnlineOffers',
)

export type VertOnlineOffer = t.TypeOf<typeof VertOnlineOfferC>
