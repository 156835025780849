import MUITextField from '@mui/material/TextField'
import { maxLength, minLength, useInput } from 'react-admin';

const PosIntInput: React.FC<{fieldName: string; label: string}> = ({
  fieldName,
  label,
}) => {
  const {
    field,
    fieldState: {isTouched, invalid, error},
    formState: {isSubmitted},
    isRequired,
  } = useInput({
    source: fieldName,
    validate: [
      minLength(10, 'phone number must have 10 digits'),
      maxLength(10, 'phone number must have 10 digits'),
    ],
  })
  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const numberOnly = [...e.target.value].filter(c => /^\d$/.test(c)).join('')
    field.onChange(numberOnly)
  }
  return (
    <MUITextField
      {...field}
      onChange={onChange}
      helperText={(isTouched || isSubmitted) && invalid ? error?.message : ''}
      label={label}
      error={(isTouched || isSubmitted) && invalid}
      required={isRequired}
    />
  )
}

export default PosIntInput
