import {Title, SimpleForm, Loading, Button, useNotify} from 'react-admin'
import * as t from 'io-ts'
import {useLocation} from 'react-router-dom'
import {constUndefined, pipe} from 'fp-ts/lib/function'
import {StoreOffersC} from 'shared/dist/types/Store'
import {FC, useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import LogoInput from './comps/LogoInput'
import React from 'react'
import {E, O, RA, TE} from 'shared/dist/fp-ts-imp'
import {lookupAddress} from './services/addressValidationService'
import {useNavigate} from 'react-router-dom'
import {
  searchStores,
  getImageUrl,
  createStoreWithImageTE,
  updateStoreTE,
  createOfferTE,
  updateOfferTE,
  RAImageInputFieldC,
} from './dataProvider'
import {Offer, vertCatToName} from 'shared/dist/types/Offer'
import {useFormContext} from 'react-hook-form'
import {runEffect} from 'shared/dist/fp-ts-util'
import ImageInput from './comps/ImageInput'
import {limitToName} from 'shared/dist/types/Limit'

const StoreWithOffersIdC = t.strict({
  id: t.number,
  error: t.string,
})

export const StoreOffersWithIdC = t.intersection(
  [StoreOffersC, StoreWithOffersIdC],
  'StoreOffersWithIdC',
)

export type StoreOffersWithId = t.TypeOf<typeof StoreOffersWithIdC>

const OfferField: FC<{record: StoreOffersWithId}> = ({record}) => {
  const offer = record.offers.length > 0 ? record.offers[0] : null

  return (
    <>
      {offer ? (
        <Typography component="span" variant="body2">
          {offer.title}
          <br />
          {offer.promoCode}
          <br />
          {offer.offerKey ? (
            <span className="existing">EXISTING</span>
          ) : (
            <span className="new">NEW</span>
          )}
        </Typography>
      ) : (
        ''
      )}
    </>
  )
}

const AddressField: FC<{record: StoreOffersWithId}> = ({record}) => {
  return (
    <Typography component="span" variant="body2">
      {record.address1}
      {record.address2 ? <br /> : ''}
      {record.address2 ? record.address2 : ''}
      <br />
      {record.city} {record.state} {record.zip}
    </Typography>
  )
}

const NameField: FC<{record: StoreOffersWithId}> = ({record}) => {
  return (
    <Typography component="span" variant="body2">
      {record.webUri ? (
        <a href={record.webUri ?? ''} target="_blank" rel="noreferrer">
          {record.name}
        </a>
      ) : (
        record.name
      )}
      <br />
      {record.phone}
      {record.storeLocationKey ? (
        <span className="existing">&nbsp;&nbsp;&nbsp;EXISTING</span>
      ) : (
        <span className="new">&nbsp;&nbsp;&nbsp;NEW</span>
      )}
    </Typography>
  )
}

const LocationField: FC<{record: StoreOffersWithId}> = ({record}) => {
  return (
    <Typography component="span" variant="body2">
      {record.location?.lat} <br />
      {record.location?.lng}
    </Typography>
  )
}

// The type StoreOffersWithId has to have the location prop, it is not optional
// I am guessing the data sent in to this function has the location set to 0 for lat lng?
// In any case, this overwrites that from Google address lookup API
const setStoreLocationsFromAddressLookup = (
  data: ReadonlyArray<StoreOffersWithId>,
) =>
  pipe(
    data,
    TE.traverseArray(store =>
      pipe(
        store,
        lookupAddress,
        TE.map(loc => ({
          ...store,
          location: {lat: loc.latitude ?? 0, lng: loc.longitude ?? 0},
          error: '',
        })),
      ),
    ),
  )

const checkExistingStore = (store: StoreOffersWithId) =>
  pipe(
    searchStores({
      name: store.name,
      address1: store.address1,
      zip: store.zip,
    }),
    // TE.map(foundStores => {
    //   if (foundStores && foundStores.length > 0) {
    //     console.log('WE FOUND A STORE!')
    //   }
    //   return foundStores
    // }),
    TE.chain(results =>
      RA.isEmpty(results)
        ? TE.of(null)
        : results.length > 1
        ? TE.left(
            new Error(
              `More than one store was found for ${store.name} ${results.length}`,
            ),
          )
        : TE.of(results[0]),
    ),
    //TE.mapLeft(e => ({...store, error: e?.message})),
    TE.map(result => ({
      ...store,
      storeLocationKey: result?.storeLocationKey ?? '',
      offers: result?.offers
        ? checkExistingOffer(store.offers, result.offers)
        : store.offers,
    })),
  )

const checkExistingOffer = (
  imported: ReadonlyArray<Offer>,
  existing: ReadonlyArray<Offer>,
): ReadonlyArray<Offer> =>
  pipe(
    imported,
    RA.map(imp =>
      pipe(
        RA.findFirst<Offer>(x => x.title === imp.title)(existing),
        O.map(x => ({...imp, offerKey: x.offerKey})),
        O.getOrElse(() => imp),
      ),
    ),
  )

const checkExisting = (data: ReadonlyArray<StoreOffersWithId>) =>
  pipe(data, TE.traverseArray(checkExistingStore))

const getOffer = (record: StoreOffersWithId) =>
  record.offers.length > 0 ? record.offers[0] : null

const maxChars = (text: string | null | undefined, maxChars_: number) =>
  text && text.length > maxChars_ ? `${text.substring(0, maxChars_)} ...` : text

const SimpleGrid: React.FC<{data: ReadonlyArray<StoreOffersWithId>}> = ({
  data,
}) => {
  return (
    <table className="importGrid">
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>Location</th>
          <th>Description</th>
          <th>Offer</th>
          <th>Category</th>
          <th>Limit</th>
          <th>Terms</th>
        </tr>
      </thead>
      <tbody>
        {data.map(x => (
          <tr key={x.id}>
            <td className="name">
              <NameField record={x} />
            </td>
            <td>
              <AddressField record={x} />
            </td>
            <td>
              <LocationField record={x} />
            </td>
            <td className="description">{x.description}</td>
            <td>
              <OfferField record={x} />
            </td>
            <td>{vertCatToName(x.offers[0]?.vertCat)}</td>
            <td>{limitToName(x.offers[0]?.limit ?? 'none')}</td>
            <td className="terms" title={getOffer(x)?.termsOfUse}>
              {maxChars(getOffer(x)?.termsOfUse, 65)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const getImageUrls = (
  logoImage: Record<string, unknown>,
  offerImage: Record<string, unknown>,
) =>
  pipe(
    TE.Do,
    TE.bindW('logoImageD', () =>
      TE.fromEither(RAImageInputFieldC.decode(logoImage)),
    ),
    TE.bindW('offerImageD', () =>
      TE.of(
        pipe(
          RAImageInputFieldC.decode(offerImage),
          E.getOrElseW(constUndefined),
        ),
      ),
    ),
    TE.bindW('logoUri', ({logoImageD}) => getImageUrl(logoImageD)),
    TE.bindW('offerImageUri', ({offerImageD}) =>
      offerImageD ? getImageUrl(offerImageD) : TE.of(''),
    ),
    TE.map(({logoUri, offerImageUri}) => [logoUri, offerImageUri]),
  )

const insertStore = (store: StoreOffersWithId) => createStoreWithImageTE(store)
const updateStore = (store: StoreOffersWithId) => updateStoreTE(store)

const insertOffer = createOfferTE
const updateOffer = updateOfferTE

const insertOrUpdateOffer = (offer: Offer & {id: string}) =>
  offer.offerKey ? updateOffer(offer) : insertOffer(offer)

const insertOrUpdateStore = (store: StoreOffersWithId) =>
  pipe(
    store.storeLocationKey ? updateStore(store) : insertStore(store),
    TE.map(x =>
      RA.map((o: Offer) => ({
        ...o,
        storeLocationKey: x.data.storeLocationKey,
        id: o.offerKey,
      }))(store.offers),
    ),
    x => x,
    TE.chainW(TE.traverseArray(insertOrUpdateOffer)),
  )

const SubmitButton: React.FC<{
  data: ReadonlyArray<StoreOffersWithId>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  disabled: boolean
}> = ({data, setIsLoading, disabled}) => {
  const notify = useNotify()
  const {getValues} = useFormContext()
  const nav = useNavigate()

  const submitImportData: React.MouseEventHandler<HTMLButtonElement> = evt => {
    const logoImage = getValues('logo')
    const offerImage = getValues('offerImage')

    evt.preventDefault()
    setIsLoading(true)
    pipe(
      getImageUrls(logoImage, offerImage),
      TE.map(([logoImageUri, offerImageUri]) =>
        RA.map((s: StoreOffersWithId) => ({
          ...s,
          logoUri: logoImageUri,
          offers: pipe(
            s.offers,
            RA.map(offer => ({
              ...offer,
              offerImageUri: offerImageUri,
            })),
          ),
        }))(data),
      ),
      TE.chainW(TE.traverseArray(insertOrUpdateStore)),
      TE.map(() => {
        setIsLoading(false)
        notify('Import was successful')
        nav('/stores')
      }),
      TE.mapLeft(e => {
        console.log(e)
        setIsLoading(false)
        notify('Error during import (see console for details).')
      }),
      runEffect,
    )
  }

  return (
    <Button
      label="Import Stores & Offers"
      title="Import Stores & Offers"
      disabled={disabled}
      className="saveButton"
      onClick={submitImportData}
    />
  )
}

export const ImportForm = () => {
  const location = useLocation()

  const [data, setData] = React.useState<ReadonlyArray<StoreOffersWithId>>()
  const [saveDisabled, setSaveDisabled] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<
    string | null | undefined
  >(null)

  const onImageSet = (file: File | null) => {
    file ? setSaveDisabled(false) : setSaveDisabled(true)
  }

  useEffect(() => {
    setIsLoading(true)
    pipe(
      location.state as ReadonlyArray<StoreOffersWithId>,
      RA.mapWithIndex((i, store) => ({
        ...store,
        id: i,
      })),
      setStoreLocationsFromAddressLookup,
      TE.chain(x => checkExisting(x)),
      TE.map(setData),
      TE.map(() => setIsLoading(false)),
      TE.mapLeft(e => {
        console.log(e)
        setIsLoading(false)
        setErrorMessage(
          e?.message ??
            'Failed to get location information. Please check your data.',
        )
        return e
      }),
      runEffect,
    )
  }, [location.state])

  return (
    <div>
      {errorMessage ? (
        <div className="error">{errorMessage}</div>
      ) : (
        <SimpleForm
          style={{width: '100%'}}
          id="importForm"
          autoSave="false"
          toolbar={false}
          className="importForm">
          <Title title="Store and Offer Importer" />
          <Box display="flex" gap={2}>
            <LogoInput
              label="Store Logo"
              imageProperty="logo"
              source="logoUri"
              onSet={onImageSet}
            />
            <ImageInput
              imageProperty="offerImage"
              label="Offer Logo"
              source="offerImageUri"
            />
          </Box>
          {isLoading ? (
            <Loading />
          ) : (
            data && (
              <>
                <SimpleGrid data={data} />
                <SubmitButton
                  data={data}
                  disabled={saveDisabled}
                  setIsLoading={setIsLoading}
                />
              </>
            )
          )}
        </SimpleForm>
      )}
    </div>
  )
}
