import {pipe} from 'fp-ts/lib/function'
import * as t from 'io-ts'
import {RA} from '../fp-ts-imp'

export const stringNullable = t.union([t.string, t.null])
export const numberNullable = t.union([t.number, t.null])
export const numberOrUndefined = t.union([t.number, t.undefined, t.null])
export const stringOrUndefined = t.union([t.string, t.undefined, t.null])
export const boolOrUndefined = t.union([t.boolean, t.undefined, t.null])

export const extractErrorKeys = (errs: t.Errors) =>
  pipe(
    errs,
    RA.map(({context}) =>
      pipe(
        context,
        RA.filter(({key}) => key !== ''),
      ),
    ),
    RA.flatten,
    RA.map(({key}) => key),
  )
