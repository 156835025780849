import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Create,
  RadioButtonGroupInput,
  Identifier,
  RaRecord,
  RedirectionSideEffect,
} from 'react-admin'
import {useLocation} from 'react-router'
import ImageInput from './comps/ImageInput'
import {limitChoices} from 'shared/dist/types/Limit'
import {vertCatChoices} from 'shared/dist/types/Offer'

const redirect: RedirectionSideEffect = (
  _resource?: string,
  _id?: Identifier,
  data?: Partial<RaRecord>,
) => {
  const storeLocationKey = data?.storeLocationKey
  return `stores/${storeLocationKey}/show/1`
}

export const OfferEdit = () => {
  return (
    <Edit redirect={redirect}>
      <SimpleForm maxWidth={700}>
        <RadioButtonGroupInput
          source="vertCat"
          choices={vertCatChoices}
          isRequired
        />
        <TextInput source="title" fullWidth required />
        <ImageInput
          label="Offer Image"
          imageProperty="offerImage"
          //cropImage={false}
          source="offerImageUri"
        />
        <BooleanInput source="enabled" />
        <TextInput source="promoCode" />
        <TextInput source="termsOfUse" fullWidth multiline />
        <RadioButtonGroupInput source="limit" choices={limitChoices} />
      </SimpleForm>
    </Edit>
  )
}

export const OfferCreate = () => {
  const location = useLocation()
  const storeLocationKey = location?.state?.record?.storeLocationKey
  return (
    <Create redirect={redirect}>
      <SimpleForm
        maxWidth={700}
        validate={({vertCat}) => {
          console.log('vertCat')
          console.log(vertCat)
          return vertCat?.length > 0
            ? {}
            : {vertCat: 'Please select at least one category,'}
        }}
        defaultValues={{
          storeLocationKey,
          enabled: true,
        }}>
        <RadioButtonGroupInput
          required
          source="vertCat"
          choices={[
            {id: 'food', name: 'Restraunts'},
            {id: 'entertainment', name: 'Entertainment'},
            {id: 'services', name: 'Services'},
            {id: 'shopping', name: 'Shopping'},
          ]}
          isRequired
        />
        <TextInput required source="title" fullWidth isRequired />
        <ImageInput
          label="Offer Logo"
          source="logoUri"
          imageProperty="offerImage"
        />
        <BooleanInput source="enabled" />
        <TextInput source="promoCode" />
        <TextInput required source="termsOfUse" />
        <RadioButtonGroupInput source="limit" choices={limitChoices} />
      </SimpleForm>
    </Create>
  )
}
