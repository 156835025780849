import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  List,
  TextField,
  CheckboxGroupInput,
  NumberInput,
} from 'react-admin'
import {VertOnlineCategoryC} from 'shared/dist/types/VertOnlineOffer'
import LogoInput from './comps/LogoInput'

export const OnlineOfferList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="storeName" />
      <TextField source="description" />
      <TextField source="vertOnlineCat" />
      <TextField source="title" />
      <BooleanField source="enabled" />
      <TextField source="lastChangedOn" />
      <TextField source="lastChangedBy" />
    </Datagrid>
  </List>
)

const OnlineOfferForm = () => (
  <SimpleForm
    maxWidth={700}
    validate={({vertOnlineCats}) => {
      console.log('vertOnlineCats')
      console.log(vertOnlineCats)
      return vertOnlineCats?.length > 0
        ? {}
        : {vertOnlineCats: 'Please select at least one category,'}
    }}>
    <BooleanInput source="enabled" />
    <NumberInput source="priority" defaultValue={999} required />
    <CheckboxGroupInput
      label="Categories"
      source="vertOnlineCats"
      choices={VertOnlineCategoryC.types.map(({value}) => ({
        id: value,
        name: value,
      }))}
      // required
    />
    <LogoInput label="Online Store Logo" source="logoUri" />
    <TextInput source="storeName" label={'store name'} required />
    <TextInput
      source="description"
      label="store description"
      fullWidth
      multiline
    />
    <TextInput source="title" fullWidth required />
    <TextInput source="redemptionUri" label="URL to redeem" required />
    <TextInput source="promoCode" />
    <TextInput
      source="termsOfUse"
      label="terms of use"
      required
      fullWidth
      multiline
    />
    <TextInput source="phone" />
  </SimpleForm>
)
export const OnlineOfferEdit = () => {
  return (
    <Edit>
      <OnlineOfferForm />
    </Edit>
  )
}

export const OnlineOfferCreate = () => {
  return (
    <Create>
      <OnlineOfferForm />
    </Create>
  )
}
