import {stringOrUndefined, boolOrUndefined} from '../utils/io-ts-util'
import * as t from 'io-ts'
import {LimitC} from './Limit'
import {toLowerCase} from 'fp-ts/lib/string'
import {O, RA} from '../fp-ts-imp'
import {pipe} from 'fp-ts/lib/function'

export const VerticalCategoryC = t.union([
  t.literal('food'),
  t.literal('entertainment'),
  t.literal('services'),
  t.literal('shopping'),
])

export type VerticalCategory = t.TypeOf<typeof VerticalCategoryC>

export const vertCatChoices: Array<{id: VerticalCategory; name: string}> = [
  {id: 'food', name: 'Restaurants'},
  {id: 'entertainment', name: 'Entertainment'},
  {id: 'services', name: 'Services'},
  {id: 'shopping', name: 'Shopping'},
]

export const vertCatNameToVertCat = (name: string): VerticalCategory =>
  pipe(
    vertCatChoices,
    RA.findFirst(({name: vcName}) => toLowerCase(name) === toLowerCase(vcName)),
    O.map(({id}) => id),
    O.getOrElse<VerticalCategory>(() => 'shopping'),
  )

export const vertCatToName = (id: VerticalCategory) =>
  pipe(
    vertCatChoices,
    RA.findFirst(({id: vcId}) => id === vcId),
    O.map(({name}) => name),
    O.getOrElse(() => 'unknown'),
  )

export const OfferBaseC = t.strict(
  {
    vertCat: VerticalCategoryC,
    promoCode: stringOrUndefined,
    storeLocationKey: t.string,
    title: stringOrUndefined,
    offerImageUri: stringOrUndefined,
    termsOfUse: t.string,
    enabled: boolOrUndefined,
    limit: t.union([LimitC, t.null, t.undefined]),
    lastChangedOn: stringOrUndefined,
    lastChangedBy: stringOrUndefined,
  },
  'OfferBase',
)

const PersistedOfferC = t.strict(
  {
    offerKey: t.string,
  },
  'PersistantOffer',
)

export const OfferC = t.intersection([PersistedOfferC, OfferBaseC], 'Offers')

export type OfferBase = t.TypeOf<typeof OfferBaseC>

export type Offer = t.TypeOf<typeof OfferC>
