import * as t from 'io-ts'
import {constTrue, pipe} from 'fp-ts/lib/function'
import {O, RA} from '../fp-ts-imp'
import {lessThan_, penultimate} from '../fp-ts-util'
import { toLowerCase } from 'fp-ts/lib/string'

export type TimeStamp = number
export type History = ReadonlyArray<TimeStamp>

export const LimitC = t.union([
  t.literal('none'),
  t.literal('1pW'),
  t.literal('1pM'),
  t.literal('1pY'),
  t.literal('2pY'),
])

export const limitChoices: Array<{id: Limit; name: string}> = [
  {id: '1pW', name: 'Once per week'},
  {id: '1pM', name: 'Once per month'},
  {id: '1pY', name: 'Once per year'},
  {id: '2pY', name: 'Twice per year'},
  {id: 'none', name: 'no limits'},
]

export const limitNameToLimit = (name: string): Limit =>
  pipe(
    limitChoices,
    RA.findFirst(({name: lcName}) => toLowerCase(name) === toLowerCase(lcName)),
    O.map(({id}) => id),
    O.getOrElse<Limit>(() => 'none'),
  )

export const limitToName = (id: Limit) =>
  pipe(
    limitChoices,
    RA.findFirst(({id: lcId}) => id === lcId),
    O.map(({name}) => name),
    O.getOrElse(() => 'unknown'),
  )


export type Limit = t.TypeOf<typeof LimitC>
export type Rule = (h: History) => boolean

const msPerDay = 1000 * 60 * 60 * 24
const todayMidnight = () => new Date().setHours(0, 0, 0, 0)
const oneYearAgo = () => todayMidnight() - msPerDay * 365

const isLastRedemptionBefore = (days: number) => (h: History) =>
  pipe(
    h,
    RA.last,
    O.map(lastRedemption => lastRedemption < todayMidnight() - msPerDay * days),
    O.getOrElse(constTrue),
  )

const limits: Record<Limit, Rule> = {
  none: constTrue,
  '1pW': isLastRedemptionBefore(7),
  '1pM': isLastRedemptionBefore(30),
  '1pY': isLastRedemptionBefore(365),
  '2pY': (h: History) => {
    if (h.length < 2) return true
    return pipe(
      h,
      penultimate,
      O.map(lessThan_(oneYearAgo)),
      O.getOrElse(constTrue),
    )
  },
}

export const checkLimit = (r: Limit) => limits[r]
