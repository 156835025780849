import {pipe} from 'fp-ts/lib/function'
import {RA} from 'shared/dist/fp-ts-imp'
import {StoreOffers} from 'shared/dist/types/Store'
import * as Papa from 'papaparse'

const CSV_FILE_TYPE = 'text/csv;charset=utf-8;'

export type StoreOfferRecord = {
  storeName: string
  phone: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  description: string
  webUri: string
  couponTitle: string
  category: string
  couponCode: string
  termsOfUse: string
  // case error to match template users already are using
  Limit: string
}

const convertOffers = (storeWOs: StoreOffers) =>
  pipe(
    storeWOs.offers,
    RA.map(o => ({...storeWOs, ...o, offers: undefined})),
  )

export const storesToCsv = (stores: ReadonlyArray<StoreOffers>) =>
  pipe(
    stores,
    RA.map(convertOffers),
    RA.flatten,
    RA.map(o => ({
      storeName: o.name ?? '',
      phone: o.phone ?? '',
      address1: o.address1 ?? '',
      address2: o.address2 ?? '',
      city: o.city ?? '',
      state: o.state ?? '',
      zip: o.zip ?? '',
      description: o.description ?? '',
      webUri: o.webUri ?? '',
      couponTitle: o.title ?? '',
      category: o.vertCat ?? '',
      couponCode: o.promoCode ?? '',
      termsOfUse: o.termsOfUse ?? '',
    })),
    x =>
      RA.isEmpty(x) ? '' : Papa.unparse(x.concat(), {skipEmptyLines: true}),
  )

export const downloadFile = (fileContents: string, fileName: string) => {
  // BOM support for special characters in Excel
  const byteOrderMark = '\ufeff'
  const blob = new Blob([byteOrderMark, fileContents], {
    type: CSV_FILE_TYPE,
  })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
