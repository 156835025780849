import {Box} from '@mui/material'
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  Button,
  SimpleForm,
  TextInput,
  BooleanField,
  BooleanInput,
  Create,
  ReferenceManyField,
  Show,
  NumberField,
  useGetRecordId,
  TabbedShowLayout,
  Tab,
  ImageField,
  DateField,
  Labeled,
  useRecordContext,
  BulkDeleteButton,
  TopToolbar,
  ExportButton,
  CreateButton,
  FilterButton,
} from 'react-admin'
import UploadIcon from '@mui/icons-material/Upload'
import {TE} from 'shared/dist/fp-ts-imp'
import {useNavigate, Link} from 'react-router-dom'
import {useFormContext} from 'react-hook-form'
import {lookupAddress, Address} from './services/addressValidationService'
import React from 'react'
import './App.css'
import {pipe} from 'fp-ts/lib/function'
import {storesToCsv, downloadFile} from './services/csvDownloader'
import {getAllStoresWithOffers} from './dataProvider'
import {readStoreOffersCSV} from './services/csvImporter'
import LogoInput, {LogoImage} from './comps/LogoInput'

const storeFilters = [
  <TextInput label="Name" source="name" alwaysOn />,
  <TextInput label="City" source="city" alwaysOn />,
  <TextInput label="State" source="state" />,
  <TextInput label="Zip" source="zip" />,
  <TextInput label="Phone Number" source="phone" />,
]

const ValidateLocationButton = () => {
  const {setValue, getValues} = useFormContext()
  const onValidate = () => {
    const address: Address = {
      name: getValues('name'),
      address1: getValues('address1'),
      city: getValues('city'),
      state: getValues('state'),
      zip: getValues('zip'),
    }
    setValue('validLocation', false)
    pipe(
      lookupAddress(address),
      TE.map(location => {
        setValue('location.lat', location?.latitude ?? '')
        setValue('location.lng', location?.longitude ?? '')
        setValue('googleId', location?.googleId)

        if (!address.address1) setValue('address1', location.address.address1)
        if (!address.zip) setValue('zip', location.address.zip)
        if (!address.city) setValue('city', location.address.city)
        if (!address.state) setValue('state', location.address.state)

        if (location.address?.address1 && location.address?.zip)
          setValue('validLocation', true)

        return undefined
      }),
      TE.mapLeft(err => {
        setValue('location.lat', '')
        setValue('location.lng', '')
        alert(err.message)
        console.log(err)
      }),
    )()
  }
  return (
    <Button
      label="Lookup Address"
      variant="contained"
      size="medium"
      onClick={onValidate}
      style={{maxHeight: '45px', marginTop: '10px', minHeight: '30px'}}
    />
  )
}

const OpenMapButton = () => {
  const {getValues} = useFormContext()
  const onValidate = () => {
    const address: Address = {
      name: getValues('name'),
      address1: getValues('address1'),
      city: getValues('city'),
      state: getValues('state'),
      zip: getValues('zip'),
    }
    const googleId = getValues('googleId')
    const searchParms = encodeURIComponent(
      `${address.address1}, ${address.zip}`,
    )
    let uri = `https://www.google.com/maps/search/?api=1&query=${searchParms}`
    if (googleId) {
      console.log('i have a google id: ' + googleId)
      uri = `${uri}&query_place_id=${googleId}`
    }
    window.open(uri, '_blank')
  }
  return (
    <Button
      label="View in Google Maps"
      variant="text"
      size="medium"
      onClick={onValidate}
      style={{maxHeight: '45px', marginTop: '10px', minHeight: '30px'}}
    />
  )
}

const customExporter = () => {
  pipe(
    getAllStoresWithOffers(),
    TE.map(x => storesToCsv(x)),
    TE.map(x => downloadFile(x, 'storeOffers.csv')),
  )()
}

const defaultIcon = <UploadIcon />

const ImportButton = () => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const nav = useNavigate()
  const handleClick = () => {
    if (!hiddenFileInput.current) return
    hiddenFileInput.current.value = ''
    hiddenFileInput.current.click()
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    pipe(
      (event?.target?.files?.length ?? 0) === 1 && event.target.files?.[0]
        ? readStoreOffersCSV(event.target.files[0])
        : TE.left(new Error('No File Selected')),
      TE.map(x => {
        nav('/import', {state: x})
        return x
      }),
      TE.mapLeft(e => {
        console.log(e)
      }),
    )()
  }

  return (
    <>
      <Button onClick={handleClick} label="Import from CSV">
        {defaultIcon}
      </Button>
      <input
        type="file"
        accept=".csv"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  )
}

const StoreListActions = () => (
  <TopToolbar>
    <ImportButton />
    <CreateButton />
    <FilterButton />
    <ExportButton exporter={customExporter} />
  </TopToolbar>
)

/* For Create and Edit Store */
const StoreForm = () => {
  return (
    <SimpleForm maxWidth={700}>
      <BooleanInput source="enabled" defaultValue={true} />
      <Box display="flex" gap={2}>
        <TextInput source="name" isRequired />
        <TextInput source="phone" isRequired />
      </Box>
      <TextInput source="address1" label="Address 1" isRequired fullWidth />
      <TextInput source="address2" label="Address 2" fullWidth />
      <TextInput source="city" isRequired />
      <Box display="flex" gap={2}>
        <TextInput source="state" isRequired />
        <TextInput source="zip" isRequired />
        <ValidateLocationButton />
      </Box>
      <label>Use 'Lookup Address' to populate Latitude and Longitude</label>
      <Box display="flex" gap={2}>
        <TextInput source="location.lat" label="Latitude" isRequired />
        <TextInput source="location.lng" label="Longitude" isRequired />
        <OpenMapButton />
      </Box>

      <LogoInput label="Store Logo" source="logoUri" />
      <TextInput source="webUri" label="Web Uri" fullWidth />
      <TextInput source="description" multiline fullWidth />
    </SimpleForm>
  )
}

export const StoreCreate = () => (
  <Create>
    <StoreForm />
  </Create>
)

export const StoreEdit = () => (
  <Edit>
    <StoreForm />
  </Edit>
)

const OpenMapRecordButton = () => {
  const record = useRecordContext()
  if (!record) return null
  const onValidate = () => {
    const address: Address = {
      name: record.name,
      address1: record.address1,
      city: record.city,
      state: record.state,
      zip: record.zip,
    }
    const googleId = undefined // getValues('googleId')
    const searchParms = encodeURIComponent(
      `${address.name}, ${address.address1}, ${address.zip}`,
    )
    let uri = `https://www.google.com/maps/search/?api=1&query=${searchParms}`
    if (googleId) {
      console.log('i have a google id: ' + googleId)
      uri = `${uri}&query_place_id=${googleId}`
    }
    window.open(uri, '_blank')
  }
  return (
    <Button
      label="View in Google Maps"
      variant="text"
      size="medium"
      onClick={onValidate}
      style={{maxHeight: '45px', marginTop: '10px', minHeight: '30px'}}
    />
  )
}

/*  View Store Details
 */
export const StoreShow = () => {
  const recordId = useGetRecordId()

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Store Info">
          <BooleanField source="enabled" label="Is Enabled?" fontSize={20} />
          <Box display="flex" gap={2}>
            <Labeled label="Store Logo">
              <LogoImage source="logoUri" />
            </Labeled>
            <Box display="flex" flexDirection="column" gap={1}>
              <Labeled label="Name">
                <TextField source="name" label="Name" fontSize={20} />
              </Labeled>
              <Labeled label="Phone Number">
                <TextField source="phone" fontSize={20} />
              </Labeled>
            </Box>
          </Box>
          <TextField source="address1" fontSize={20} />
          <TextField source="address2" fontSize={20} />
          <Box display="flex" gap={2}>
            <Labeled label="City">
              <TextField source="city" fontSize={20} />
            </Labeled>
            <Labeled label="State">
              <TextField source="state" fontSize={20} />
            </Labeled>
            <Labeled label="Zip">
              <TextField source="zip" fontSize={20} />
            </Labeled>
          </Box>
          <Box display="flex" gap={2}>
            <Labeled label="Latitude">
              <NumberField source="location.lat" fontSize={20} />
            </Labeled>
            <Labeled label="Longitude">
              <NumberField source="location.lng" fontSize={20} />
            </Labeled>
            <OpenMapRecordButton />
          </Box>
          <TextField source="description" fontSize={20} />
          <Box display="flex" gap={2}>
            <Labeled label="Web URL">
              <TextField source="webUri" label="Web  Uri" fullWidth />
            </Labeled>
            <Button />
          </Box>
          <Box display="flex" gap={2}>
            <Labeled label="Last Changed">
              <TextField source="lastChangedOn" fontSize={20} />
            </Labeled>
            <Labeled label="By">
              <TextField source="lastChangedBy" fontSize={20} />
            </Labeled>
          </Box>
        </Tab>

        <Tab label="Offers">
          <div style={{paddingTop: '1rem'}}>
            <CreateButton
              // title="add offer"
              color="primary"
              label="add offer"
              component={Link}
              to={{
                pathname: '/offers/create',
                state: {record: {storeLocationKey: recordId}},
              }}
            />
          </div>
          <ReferenceManyField
            label={false}
            reference="offers"
            target="storeLocationKey">
            <h4 style={{paddingLeft: 10}}>Offers</h4>
            <Datagrid
              rowClick="edit"
              bulkActionButtons={
                <BulkDeleteButton mutationMode="pessimistic" />
              }>
              {/* <TextField source="id" /> */}
              <TextField source="title" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const StoreList = () => (
  <List filters={storeFilters} actions={<StoreListActions />}>
    <Datagrid rowClick="show">
      <ImageField
        source="logoUri"
        sx={{
          '& img': {
            verticalAlign: 'center',
            maxWidth: 50,
            maxHeight: 50,
            objectFit: 'contain',
          },
        }}
        label="Logo"
      />
      <TextField source="name" />
      <TextField source="address1" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="zip" />
      <BooleanField source="enabled" />

      <DateField source="lastChangedOn" label="Last Changed" showTime />
      <TextField source="lastChangedBy" label="By" />
    </Datagrid>
  </List>
)
