// @flow

/**
 * Credit is good, because let's be clear, this is not very good code.
 * It is a JavaScript fever dream of unexpected consequences.
 * It certainly does not fit with the rest of the code base.
 * @author Junaid Atari <mj.atari@gmail.com>
 * @link http://junaidatari.com Author Website
 * @since 2020-08-21
 */

import React from 'react'
import * as PropTypes from 'prop-types'
import ReactCropper from 'react-cropper'
import {Button, Modal} from 'react-bootstrap'

// Types
import type Cropper from 'cropperjs'
import type {ReactCropperProps} from 'react-cropper'
import type {ModalProps} from 'react-bootstrap'

// Styles
import 'cropperjs/dist/cropper.css'
import 'rc-slider/assets/index.css'

/**
 * CropperModel `props` type
 * @type {Object}
 */
type Props = {
  /** File or Blob object */
  file: File | null
  /** Labels */
  labels?: {
    heading: string
    confirm: string
    discard: string
    zoom: string
    rotate: string
  }
  /** MIME type (set null for auto retrieve) */
  mime?: string
  /** Export image quality (1~100) */
  quality?: number
  /** Initial image zoom (0.0~10.0) */
  initialZoom?: number
  /** Initial image rotate (-180~180) */
  initialRotate?: number
  /** Bootstrap modal options */
  modalProps?: ModalProps
  /** Cropper options */
  cropperProps?: ReactCropperProps
  /** Cropped canvas options */
  croppedCanvasProps?: Cropper.GetCroppedCanvasOptions
  /** Event handlers: on confirm */
  onConfirm(croppedFile: File): void
  /** Event handler: on discard */
  onDiscard(file: File | null): void
  /** Event handler: Triggers on confirm and discard executed */
  onCompleted(): void
}

/** CropperModel functional component */
function CropperModel(props: Props) {
  const {labels, file} = props
  const [cropper, setCropper] = React.useState<Cropper | null>(null)
  const [image, setImage] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (file && file.size) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImage(reader.result as string)
      })

      reader.readAsDataURL(file as File)
    } else {
      setImage(null)
      setCropper(null)
    }
  }, [props, file, cropper])

  /**
   * Crop image
   * @returns {void}
   * @event {Props:onConfirm}
   */
  const onConfirm = (): void => {
    if (!cropper) {
      return
    }

    const croppedCanvas: Cropper.GetCroppedCanvasOptions = {
      height: 300,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      minHeight: 300,
      maxHeight: 300,
      imageSmoothingQuality: 'medium',
      ...props.croppedCanvasProps,
    }

    const canvasData: HTMLCanvasElement =
      cropper.getCroppedCanvas(croppedCanvas)

    const fileInfo = getFileInfo(file as File, props.mime)

    canvasData.toBlob(
      blob => {
        if (blob) {
          const croppedFile = new File([blob], fileInfo.filename, {
            type: blob.type,
            lastModified: new Date().getTime(),
          })
          typeof props.onConfirm === 'function' && props.onConfirm(croppedFile)
          typeof props.onCompleted === 'function' && props.onCompleted()
          setImage(null)
          setCropper(null)
        }
      },
      fileInfo.mime,
      props.quality,
    )
  }

  const handleClose = (): void => {
    // love this effing magic shite (oh, this causes the modal to close, obviously)
    setCropper(null)
    setImage(null)
    typeof props.onDiscard === 'function' && props.onDiscard(file)
    typeof props.onCompleted === 'function' && props.onCompleted()
  }

  return (
    <Modal
      show={!!file && !!image}
      onHide={handleClose}
      animation={false}
      size="xl"
      {...props.modalProps}>
      <Modal.Header closeButton>
        <Modal.Title>{labels?.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {image && (
          <ReactCropper
            src={image}
            style={{height: 300, width: '100%'}}
            initialAspectRatio={1 / 1}
            viewMode={1}
            dragMode="crop"
            cropBoxResizable={true}
            cropBoxMovable={true}
            center={true}
            onInitialized={setCropper}
            {...props.cropperProps}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button variant="primary" className="confirmBtn" onClick={onConfirm}>
          {labels?.confirm}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {labels?.discard}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

CropperModel.propTypes = {
  initialZoom: PropTypes.number,
  initialRotate: PropTypes.number,
  mime: PropTypes.string,
  quality: PropTypes.number,
  file: PropTypes.object,
  labels: PropTypes.shape({
    heading: PropTypes.string,
    confirm: PropTypes.string,
    discard: PropTypes.string,
    zoom: PropTypes.string,
    rotate: PropTypes.string,
  }),
  cropperProps: PropTypes.object,
  modalProps: PropTypes.object,
  croppedCanvasProps: PropTypes.object,
  onDiscard: PropTypes.func,
  onCompleted: PropTypes.func,
}

CropperModel.defaultProps = {
  initialZoom: 0,
  initialRotate: 0,
  initialAspectRatio: 1,
  mime: null,
  quality: 70,
  labels: {
    heading: 'Crop Image',
    confirm: 'Confirm',
    discard: 'Discard',
    zoom: 'Zoom',
    rotate: 'Rotate',
  },
  modalProps: {},
  cropperProps: {},
  croppedCanvasProps: {},
  onDiscard: () => undefined,
  onCompleted: () => undefined,
}

const getFileInfo = (
  file: File,
  mime = '',
): {filename: string; mime: string} => {
  const pos: number = String(file.name).lastIndexOf('.')

  if (mime === 'image/jpeg') {
    const filename = `${String(file.name).substr(
      0,
      pos < 0 ? String(file.name).length : pos,
    )}.jpg`

    return {
      filename,
      mime: 'image/jpeg',
    }
  }

  return {
    filename: file.name,
    mime: file.type,
  }
}

export default CropperModel
