import {
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  List,
  TextField,
} from 'react-admin'

export const BlacklistStoreList = () => (
  <List>
    Stores in this list will not be displayed on map in Discount app.
    <br />
    <br />
    <Datagrid rowClick="edit">
      <TextField source="storeKey" />
      <TextField source="storeName" />
      <TextField source="storeDescription" />
    </Datagrid>
  </List>
)

export const BlacklistStoreForm = () => (
  <SimpleForm maxWidth={700}>
    <TextInput source="storeKey" />
    <TextInput source="storeName" />
    <TextInput source="storeDescription" />
  </SimpleForm>
)

// export const BlacklistStoreEdit = () => {
//   return (
//     <Edit>
//       <BlacklistStoreForm />
//     </Edit>
//   )
// }

export const BlacklistStoreCreate = () => {
  return (
    <Create>
      <BlacklistStoreForm />
    </Create>
  )
}
