import {Admin, CustomRoutes, DataProvider, Resource} from 'react-admin'
import {Route} from 'react-router-dom'
import authProvider from './authProvider'
import dataProvider from './dataProvider'
import {OfferCreate, OfferEdit} from './offerUI'
import {StoreList, StoreCreate, StoreShow, StoreEdit} from './storeUI'
import {UserList, UserEdit, UserCreate} from './userUI'
import {ImportForm} from './importerUI'
import {OnlineOfferCreate, OnlineOfferEdit, OnlineOfferList} from './onlineOfferUI'
import { BlacklistStoreCreate, BlacklistStoreList } from './blacklistStoreUI'

export const users = 'users'
export const stores = 'stores'
export const offers = 'offers'
export const onlineOffers = 'online-offers'
export const blacklistStores = 'blacklist-stores'

const App = () => (
  // we have to do this really terrible typecase because React Admin is 
  // simply the most terrible framework ever created.  More specifically,
  // it is a pile of JavaScript that has be typed by JavaScript coders
  // who are not competent to type code or write it.
  <Admin dataProvider={dataProvider as unknown as DataProvider} authProvider={authProvider}>
    <Resource name={users} list={UserList} edit={UserEdit} create={UserCreate} />
    <Resource
      name={stores}
      options={{label: 'Store Locations'}}
      list={StoreList}
      create={StoreCreate}
      show={StoreShow}
      edit={StoreEdit}
    />
    <Resource name={offers} create={OfferCreate} edit={OfferEdit} />
    <Resource
        name={onlineOffers}
        options={{label: 'Online Offers'}}
        create={OnlineOfferCreate}
        edit={OnlineOfferEdit}
        list={OnlineOfferList}
      />
    <Resource
        name={blacklistStores}
        options={{label: 'Access Store Blacklist'}}
        create={BlacklistStoreCreate}
        list={BlacklistStoreList}
      />
    <CustomRoutes>
      <Route path="/import" element={<ImportForm />} />
    </CustomRoutes>
  </Admin>
)
export default App
