import {pipe} from 'fp-ts/lib/function'
import {
  //PlacesAutocomplete
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import {TE} from 'shared/dist/fp-ts-imp'
import {TaskEither} from 'fp-ts/lib/TaskEither'

export type Address = {
  name: string
  address1: string
  city: string
  state: string
  zip: string
}

export type Location = {
  address: Address
  latitude: number | undefined
  longitude: number | undefined
  googleId: string | undefined
}

export const parseGeocoderResult = (
  geo: google.maps.GeocoderResult,
): Address => {
  const address: Address = {
    name: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
  }
  let address1_number = ''
  let address1_street = ''
  //const addressComponents = RA.of(geo.address_components)
  for (let i = 0; i < geo.address_components.length; i++) {
    const comp = geo.address_components[i]
    for (let t = 0; t < comp.types.length; t++) {
      const addType = comp.types[t]
      switch (addType) {
        case 'street_number':
          address1_number = comp.short_name
          break
        case 'route': // street
          address1_street = comp.short_name
          break
        case 'neighborhood': // city
          address.city = comp.short_name
          break
        case 'locality': // also city
          address.city = comp.short_name
          break
        case 'administrative_area_level_1': // state
          address.state = comp.short_name
          break
        case 'postal_code':
          address.zip = comp.short_name
          console.log('found zip ' + comp.short_name)
          break
      }
    }
  }
  if (address1_number && address1_street)
    address.address1 = `${address1_number} ${address1_street}`
  //   .RA.findFirst(
  //     x => (x as google.maps.GeocoderAddressComponent).types.length > 0,
  //   )(geo.address_components)
  return address
}

export const lookupAddress = (
  address: Address,
): TaskEither<Error, Location> => {
  console.log('Looking up address')
  return pipe(
    TE.of(`${address.name}, ${address.address1},
      ${address.city},
      ${address.state},
      ${address.zip}`),
    TE.map(addressStr => addressStr),
    TE.chainW(addressStr =>
      TE.tryCatch(
        () =>
          new Promise<google.maps.GeocoderResult>((resolve, reject) => {
            geocodeByAddress(addressStr)
              .then(results => {
                // results is a list of possible addresses.
                // what we really need to do here is filter those
                //  results to exclude duplicate lat/lng because google
                // can return the same location in multiple results
                console.log(results)
                if (results && results.length > 0) {
                  resolve(results[0])
                } else {
                  reject(new Error('No Results'))
                }
              })
              .catch(reason => {
                console.log(reason)
                const error =
                  address.address1 && address.address1.length > 0
                    ? `Failed to find location for ${address.address1 ?? ''}  ${
                        address.city ?? ''
                      }  ${address.state ?? ''}  ${address.zip ?? ''}`
                    : 'Invalid address: [BLANK]'
                return reject(error)
              })
          }),
        reason => new Error(JSON.stringify(reason)),
      ),
    ),
    TE.chainW(topGeoResult =>
      TE.tryCatch(
        () =>
          new Promise<Location>((resolve, reject) =>
            getLatLng(topGeoResult)
              .then(results => {
                resolve({
                  address: parseGeocoderResult(topGeoResult),
                  latitude: parseFloat(results.lat.toFixed(12)),
                  longitude: parseFloat(results.lng.toFixed(12)),
                  googleId: topGeoResult.place_id,
                })
              })
              .catch(reject),
          ),
        reason => new Error(JSON.stringify(reason)),
      ),
    ),
    x => x,
  )
}
