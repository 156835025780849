import {pipe} from 'fp-ts/lib/function'
import {RA, TE} from 'shared/dist/fp-ts-imp'
import {StoreOffers} from 'shared/dist/types/Store'
import * as Papa from 'papaparse'
import {StoreOfferRecord} from './csvDownloader'
import {limitNameToLimit} from 'shared/dist/types/Limit'
import { tapConsoleTE } from 'shared/dist/utils/debug'
import { vertCatNameToVertCat } from 'shared/dist/types/Offer'

const convertToStoreOffers = (record: StoreOfferRecord): StoreOffers => ({
  storeLocationKey: '',
  storeBrandKey: '',
  logoUri: '',
  enabled: true,
  location: {lat: 0, lng: 0},
  name: record.storeName,
  phone: record.phone,
  address1: record.address1,
  address2: record.address2,
  city: record.city,
  state: record.state,
  zip: record.zip,
  description: record.description,
  webUri: record.webUri,
  lastChangedOn: '',
  lastChangedBy: '',
  offers: [
    {
      offerKey: '',
      title: record.couponTitle,
      offerImageUri: undefined,
      vertCat: vertCatNameToVertCat(record.category),
      promoCode: record.couponCode,
      termsOfUse: record.termsOfUse,
      storeLocationKey: '',
      enabled: true,
      limit: limitNameToLimit(record.Limit),
      lastChangedOn: '',
      lastChangedBy: '',
    },
  ],
})

export const readStoreOffersCSV = (file: File) =>
  pipe(
    TE.of(file),
    TE.chainW(() =>
      TE.tryCatch(
        () =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event: ProgressEvent<FileReader>) =>
              resolve(event.target?.result)
            reader.onerror = event => reject(event)
            reader.readAsText(file)
          }),
        reason => new Error(JSON.stringify(reason)),
      ),
    ),
    TE.map(x =>
      Papa.parse<StoreOfferRecord>(x as string, {
        header: true,
        skipEmptyLines: true,
      }),
    ),
    tapConsoleTE('parsed store offer records'),
    TE.chain(x =>
      x.data.length
        ? TE.of(x.data)
        : x.errors.length
        ? TE.left(new Error(JSON.stringify(x.errors)))
        : TE.left(new Error('no data')),
    ),
    TE.map(RA.map(convertToStoreOffers)),
  )
