import * as t from 'io-ts'

// These types are specifically for managing Access stores blacklist,
// so we don't need or want to unify with the Store type that already
// exists and has business meaning.
export const BLAccessStoreC = t.strict({
  storeKey: t.string,
  storeName: t.string,
  storeDescription: t.string,
}, 'BlacklistAccessStores')

export type BLAccessStore = t.TypeOf<typeof BLAccessStoreC>
