import {flow, Lazy} from 'fp-ts/lib/function'
import {lt} from 'fp-ts/lib/Ord'
import {N, O, RA} from './fp-ts-imp'

export const runEffect = (f: () => void) => f()
export const penultimate = flow(RA.init, O.chain(RA.last))
export const lessThan = (second: number) => (first: number) =>
  lt(N.Ord)(first, second)
export const lessThan_ = (second: Lazy<number>) => (first: number) =>
  lt(N.Ord)(first, second())
