import {Box} from '@mui/material'
import {useState} from 'react'
import {ImageField, Labeled, useRecordContext} from 'react-admin'
import CropperModel from './CropperModel'
import {useFormContext} from 'react-hook-form'

const CropImagePopup: React.FC<{
  // the typings in "CropperModel" are bad and wrong, so we have to use any here
  onSetResizedImage: React.Dispatch<React.SetStateAction<string>>
  file: File | null
  onSetFile: React.Dispatch<React.SetStateAction<File | null>>
  onSetCroppedFile?: (value: File | null) => void
}> = ({onSetResizedImage, file, onSetFile, onSetCroppedFile}) => {
  const {setValue} = useFormContext()

  const onImageConfirmed = (croppedFile: File) => {
    setValue(
      'logo',
      {rawFile: croppedFile, title: 'logo Image'},
      {shouldDirty: true},
    )
    onSetResizedImage(window.URL.createObjectURL(croppedFile))
    onSetCroppedFile?.(croppedFile)
  }

  return (
    <CropperModel
      file={file}
      onConfirm={onImageConfirmed}
      onCompleted={() => onSetFile(null)}
    />
  )
}

export const LogoImage: React.FC<{source: string}> = ({source}) => (
  <ImageField
    source={source}
    sx={{
      '& img': {
        verticalAlign: 'center',
        maxWidth: 120,
        maxHeight: 120,
        objectFit: 'contain',
      },
    }}
    title="Current Image"
    label="Current Image"
  />
)

export const SetImageButton: React.FC<{
  id: string
  onSetFile: (file: File | null) => void
}> = ({id, onSetFile}) => {
  const handleFileEvent: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files && e.target.files.length > 0) {
      onSetFile(e.target.files[0])
    }

    e.target.value = ''
  }

  return (
    <div>
      <label
        htmlFor={`filePicker_${id}`}
        style={{
          background: 'rgba(0, 0, 0, 0.04)',
          color: 'rgba(0, 0, 0, 0.74)',
          padding: '15px 10px',
          margin: '0px 0px 0px 10px',
        }}>
        Drop a picture to upload, or click to select it.
      </label>
      <input
        id={`filePicker_${id}`}
        accept="image/jpeg,image/png"
        style={{visibility: 'hidden', margin: '15px 0px 20px 0px'}}
        onChange={handleFileEvent}
        type={'file'}></input>
    </div>
  )
}

const LogoInput: React.FC<{
  label: string
  source: string
  imageProperty?: string
  onSet?: (file: File) => void
}> = ({label, source, onSet}) => {
  const [resizedImage, setResizedImage] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const record = useRecordContext()

  const setCroppedFile = (value: File | null): void => {
    if (value) onSet?.(value)
  }

  return (
    <>
      <CropImagePopup
        file={file}
        onSetFile={setFile}
        onSetResizedImage={setResizedImage}
        onSetCroppedFile={setCroppedFile}
      />

      <Labeled label={label}>
        <Box display="flex" gap={2}>
          {resizedImage ? (
            <img
              src={resizedImage}
              alt="Cropped preview"
              style={{height: 120, width: 'auto'}}
            />
          ) : (
            record?.[source] && <LogoImage source={source} />
          )}
          <SetImageButton id={source} onSetFile={setFile} />
        </Box>
      </Labeled>
    </>
  )
}

export default LogoInput
