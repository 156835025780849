import * as EI from 'fp-ts/Either'
import * as EQI from 'fp-ts/Eq'
import * as MI from 'fp-ts/Map'
import * as NI from 'fp-ts/number'
import * as OI from 'fp-ts/Option'
import * as OrdI from 'fp-ts/Ord'
import * as RI from 'fp-ts/Record'
import * as RAI from 'fp-ts/ReadonlyArray'
import * as TEI from 'fp-ts/TaskEither'
import * as RNEAI from 'fp-ts/ReadonlyNonEmptyArray'
import * as SI from 'fp-ts/string'

export const E = EI
export const Eq = EQI
export const M = MI
export const N = NI
export const O = OI
export const Ord = OrdI
export const R = RI
export const RA = RAI
export const RNEA = RNEAI
export const S = SI
export const TE = TEI
