import {
  Identifier,
  PaginationPayload,
  GetListParams,
  GetListResult,
  RaRecord,
  GetOneResult,
  GetManyResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  UpdateResult,
  CreateResult,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
} from 'ra-core'
import * as t from 'io-ts'
import {pipe} from 'fp-ts/lib/function'
import {
  DecodeError,
  deleteAndDecode,
  FetchError,
  getAndDecode,
  HTTPError,
  postAndDecode,
  putAndDecode,
} from 'shared'
import {tapConsole, tapConsoleLTE, tapConsoleTE} from 'shared/dist/utils/debug'
import {stringOrUndefined} from 'shared/dist/utils/io-ts-util'
import {E, R, RA, S, TE} from 'shared/dist/fp-ts-imp'
import {Store, StoreBaseC, StoreC, StoreOffersC} from 'shared/dist/types/Store'
import {OfferBaseC, OfferC} from 'shared/dist/types/Offer'
import {VertOnlineOfferC} from 'shared/dist/types/VertOnlineOffer'
import {BLAccessStoreC} from 'shared/dist/types/BLAccessStore'
import {TaskEither} from 'fp-ts/TaskEither'
import {blacklistStores, offers, onlineOffers, stores, users} from './App'
import {PathReporter} from 'io-ts/lib/PathReporter'

const _currentHost = process.env.REACT_APP_BACKEND_URL
const currentHost = `${_currentHost}/portal`

export const PlatformC = t.union([t.literal('iOS'), t.literal('Android')])

const NotificationTokenC = t.strict({
  token: t.string,
  lastUpdated: t.string,
  platform: PlatformC,
})

export const CountryC = t.union([t.literal('US'), t.literal('CA')])

export type Country = t.TypeOf<typeof CountryC>

const UserC = t.type(
  {
    accessCVT: stringOrUndefined,
    email: t.string,
    fundraiserName: stringOrUndefined,
    name: t.string,
    phoneNbr: t.string,
    participantImageUrl: t.string,
    pushTokens: t.union([t.array(NotificationTokenC), t.undefined, t.null]),
    address1: stringOrUndefined,
    address2: stringOrUndefined,
    city: stringOrUndefined,
    state: stringOrUndefined,
    zip: stringOrUndefined,
    country: t.union([CountryC, t.undefined, t.null]),
    stripeToken: stringOrUndefined,
    stripeId: stringOrUndefined,
    subscriptionStarted: t.boolean,
    fundraiserId: stringOrUndefined,
    subscriptionStartDate: stringOrUndefined,
    subscriptionEndDate: stringOrUndefined,
    experiments: t.union([t.record(t.string, t.string), t.undefined, t.null]),
    jwt: stringOrUndefined,
  },
  'UsersC',
)

const UserWIdC = t.intersection([t.type({id: t.string}), UserC])

export type AdminUser = t.TypeOf<typeof UserWIdC>

const GetUserResponseC = UserWIdC

const UpdateUserResponse = t.type(
  {
    success: t.boolean,
  },
  'UpdateUserResponse',
)

const CreateUserResponse = t.type(
  {
    success: t.boolean,
    user: UserWIdC,
  },
  'CreateUserResponse',
)

const PageListStoresResponseC = t.type({
  data: t.readonlyArray(StoreC, 'PageListStoresResponseC'),
  page: t.number,
  total: t.number,
  pageSize: t.number,
  totalPages: t.number,
})

const PageListUsersResponseC = t.type({
  data: t.readonlyArray(UserWIdC, 'PageListUsersResponseC'),
  page: t.number,
  total: t.number,
  pageSize: t.number,
  totalPages: t.number,
})

const StoresWithOffersResponseC = t.readonlyArray(
  StoreOffersC,
  'StoresWithOffers',
)

const ListOffersResponseC = t.readonlyArray(OfferC, 'ListOffersResponseC')

const OnlineOfferWKeyC = t.intersection([
  t.type({key: t.string}),
  VertOnlineOfferC,
])
type OnlineOfferWKey = t.TypeOf<typeof OnlineOfferWKeyC>

const ListOnlineOffersResponseC = t.readonlyArray(
  OnlineOfferWKeyC,
  'ListOffersResponseC',
)

const BlacklistStoreWKeyC = t.intersection([
  t.type({key: t.string}),
  BLAccessStoreC,
])
type BlacklistStoreWKey = t.TypeOf<typeof BlacklistStoreWKeyC>

const ListBlacklistStoresResponseC = t.readonlyArray(
  BlacklistStoreWKeyC,
  'BlacklistStoreWKeyC',
)

const AddAccessTravelUserResponse = t.type(
  {
    cvt: t.string,
  },
  'AddAccessTravelUserResponseC',
)

// this type comes from React Admin form behavior and is not really typed.
// I have not tracked down what the actual type is, if there is one, so I am
// guessing based on usage and trying to prevent coding errors.
export const RAImageInputFieldC = t.type({
  rawFile: t.type({
    name: t.string,
    arrayBuffer: t.Function,
    size: t.number,
    type: t.string,
    slice: t.Function,
    stream: t.Function,
    text: t.Function,
  }),
})
export type RAImageInputField = t.TypeOf<typeof RAImageInputFieldC>

const hctaCyrt = <E, A>(someTE: TaskEither<E, A>): Promise<A> => {
  return new Promise((resolve, reject) =>
    someTE().then(ea =>
      E.isRight(ea)
        ? resolve(ea.right)
        : reject(
            typeof ea.left === 'object' && ea.left && 'message' in ea.left
              ? ea.left.message
              : ea.left,
          ),
    ),
  )
}

// React Admin require that all "elements" in collections have a field named `id`, and by default
// it asks for a sort on that field.  We have to fix that up before going back to the API
const sortFix = //R.deleteAt('id')
  R.reduceWithIndex(S.Ord)({}, (k, acc, v) =>
    k === 'id' ? {...acc, _id: v} : {...acc, [k]: v},
  )

const inMs = (ts: number) => (ts < 9999999999 ? ts * 1000 : ts)
const OneYearInMs = 365 * 24 * 60 * 60 * 1000
const timestampFromObjectIdString = (objectId: string) =>
  parseInt(objectId.substring(0, 8), 16) ?? 0 * 1000

const deriveSubEndDate = ({
  subscriptionEndDate,
  subscriptionStartDate,
  id,
}: AdminUser) =>
  subscriptionEndDate
    ? inMs(parseInt(subscriptionEndDate, 10))
    : subscriptionStartDate
    ? parseInt(subscriptionStartDate, 10) + OneYearInMs
    : timestampFromObjectIdString(id) + OneYearInMs

const listUsersTE = (
  filter: Record<string, string>,
  sort: {
    [x: string]: string
  },
  pagination: PaginationPayload,
) =>
  pipe(
    postAndDecode(PageListUsersResponseC)(`${currentHost}/users/pageList`, {
      body: JSON.stringify({
        filter,
        sort: sortFix(sort),
        pagination,
      }),
      credentials: 'include',
    }),
    TE.map(({data, ...rest}) => {
      const data_ = pipe(
        data,
        RA.map(u => ({
          ...u,
          subscriptionEndDate: new Date(deriveSubEndDate(u)),
        })),
      )
      return {data: data_, ...rest}
    }),
    TE.map(results => ({
      data: [...results.data],
      total: results.total,
      pageInfo: {
        hasNextPage: results.page < results.totalPages,
        hasPreviousPage: results.page > 1,
      },
    })),
  )

const getOneUserTE = (id: string | number) =>
  pipe(
    getAndDecode(GetUserResponseC)(`${currentHost}/users/${id}`, {
      credentials: 'include',
    }),
    TE.map(u => ({
      ...u,
      subscriptionEndDate: new Date(deriveSubEndDate(u)),
    })),
    TE.mapLeft(err => console.log(JSON.stringify(err))),
    TE.map(u => ({data: u})),
  )

const updateUserTE = (id: string | number, user: RaRecord) =>
  pipe(
    user,
    ({subscriptionEndDate, ...u}) => ({
      ...u,
      subscriptionEndDate: subscriptionEndDate
        ? `${new Date(subscriptionEndDate).getTime() / 1000}`
        : undefined,
    }),
    UserWIdC.decode,
    TE.fromEither,
    TE.chainW(userD =>
      postAndDecode(UpdateUserResponse)(`${currentHost}/users/update-user`, {
        body: JSON.stringify({id: `${id}`, user: userD}),
        credentials: 'include',
      }),
    ),
    TE.map(() => ({data: user})),
  )

const createUserTE = (user: RaRecord) =>
  pipe(
    UserC.decode(user),
    TE.fromEither,
    TE.map(({subscriptionEndDate, ...u}) => ({
      ...u,
      subscriptionEndDate: `${
        new Date(subscriptionEndDate ?? '').getTime() / 1000
      }`,
    })),
    TE.mapLeft(err => PathReporter.report(E.left(err))),
    TE.chainW(userD =>
      postAndDecode(CreateUserResponse)(`${currentHost}/users/create-user`, {
        body: JSON.stringify({user: userD}),
        credentials: 'include',
      }),
    ),
    TE.map(({user: {id}}) => ({data: {...user, id}})),
    TE.mapLeft(err => ('message' in err ? err.message : err)),
    tapConsoleLTE('message?'),
  )

const convertStores = (data: ReadonlyArray<Store>) =>
  data.map(store => ({
    ...store,
    id: store.storeLocationKey,
  }))

const listStoresTE = (
  filter: Record<string, string>,
  sort: {
    [x: string]: string
  },
  pagination: PaginationPayload,
) =>
  pipe(
    postAndDecode(PageListStoresResponseC)(`${currentHost}/stores/pagelist`, {
      body: JSON.stringify({
        filter,
        sort: sortFix(sort),
        pagination,
      }),
      credentials: 'include',
    }),
    TE.map(results => ({
      data: convertStores(results.data),
      total: results.total,
      pageInfo: {
        hasNextPage: results.page < results.totalPages,
        hasPreviousPage: results.page > 1,
      },
    })),
  )

export const searchStores = (filter: Partial<Record<keyof Store, string>>) =>
  pipe(
    postAndDecode(StoresWithOffersResponseC)(
      `${currentHost}/stores/listWithOffers`,
      {
        body: JSON.stringify({filter: filter}),
        credentials: 'include',
      },
    ),
  )

const getOneStoreTE = (id: string | number) =>
  pipe(
    getAndDecode(StoreC)(`${currentHost}/stores/${id}`, {
      credentials: 'include',
    }),
    TE.mapLeft(err => console.log(JSON.stringify(err))),
    TE.map(store => ({
      data: {...store, id: store.storeLocationKey},
    })),
  )

const getManyStoresTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(getOneStoreTE),
    TE.map(RA.map(({data}) => data)),
    TE.map(ss => ({data: [...ss], total: ss.length})),
  )

export const getImageUrl = (
  logoData: RAImageInputField,
): TaskEither<HTTPError | DecodeError | FetchError | Error, string> => {
  const fd = new FormData()
  // I could not find a complete codec or type guard for the Blob type.
  // The codec for RAImageInputField comes reasonably close... it just does
  // not check the function types for anything other than they are a function,
  // but I am assuming that if there are all functions with the right name, it
  // is a valid blob.
  const blob = logoData.rawFile as unknown as Blob
  fd.append('logo', blob, logoData.rawFile.name)
  return pipe(
    postAndDecode(t.type({objectUrl: t.string}))(
      `${currentHost}/images/upload-image`,
      {
        body: fd,
        credentials: 'include',
        headers: undefined,
      },
    ),
    TE.map(({objectUrl}) => objectUrl),
  )
}

const createStoreTE = (store: Record<string, unknown>) =>
  pipe(
    tapConsole('store')(store),
    () => RAImageInputFieldC.decode(store?.logo),
    TE.fromEither,
    TE.chainW(getImageUrl),
    TE.chainW(logoUri => TE.fromEither(StoreBaseC.decode({...store, logoUri}))),
    TE.chainW(newStore => {
      return postAndDecode(StoreC)(`${currentHost}/stores`, {
        body: JSON.stringify(newStore),
        credentials: 'include',
      })
    }),
    TE.map(newStore => ({
      data: {...newStore, id: newStore.storeLocationKey},
    })),
  )

export const createStoreWithImageTE = (store: Store) =>
  pipe(
    TE.of(store),
    TE.chainW(newStore =>
      postAndDecode(StoreC)(`${currentHost}/stores`, {
        body: JSON.stringify(newStore),
        credentials: 'include',
      }),
    ),
    TE.map(newStore => ({
      data: {...newStore, id: newStore.storeLocationKey},
    })),
  )

const deleteStoreTE = (id: string | number) =>
  pipe(
    deleteAndDecode(t.any)(`${currentHost}/stores/${id}`, {
      credentials: 'include',
    }),
  )

const deleteManyStoresTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(deleteStoreTE),
    TE.map(() => ({data: [...ids]})),
  )

const StoreWithLogoC = t.intersection([
  StoreC,
  t.partial({logo: RAImageInputFieldC}),
])

export const updateStoreTE = (store: Record<string, unknown>) => {
  return pipe(
    TE.fromEither(StoreWithLogoC.decode(store)),
    TE.mapLeft(err => {
      console.error('store')
      console.error(store)
      console.error('err')
      console.error(err)
      return new Error('Update: On Missing Required Field(s).')
    }),
    TE.chain(storeD =>
      storeD?.logo
        ? pipe(
            getImageUrl(storeD?.logo),
            TE.map(logoUri => ({...storeD, logoUri})),
          )
        : TE.of(storeD),
    ),
    TE.chainW(storeD_ =>
      putAndDecode(StoreC)(`${currentHost}/stores`, {
        body: JSON.stringify(storeD_),
        credentials: 'include',
      }),
    ),
    TE.map(udpatedStore => ({
      data: {...udpatedStore, id: udpatedStore.storeLocationKey},
    })),
    TE.mapLeft(err => {
      console.log(err)
      return err
    }),
  )
}

const listOffersTE = (
  filter: Record<string, string>,
  sort: {
    [x: string]: string
  },
) =>
  pipe(
    postAndDecode(ListOffersResponseC)(`${currentHost}/offers/list`, {
      body: JSON.stringify({
        filter,
        sort: sortFix(sort),
      }),
      credentials: 'include',
    }),
    TE.map(RA.map(offer => ({...offer, id: offer.offerKey}))),
    TE.map(os => ({data: [...os], total: os.length})),
  )

const getOneOfferTE = (id: string | number) =>
  pipe(
    getAndDecode(OfferC)(`${currentHost}/offers/${id}`, {
      credentials: 'include',
    }),
    TE.mapLeft(err => console.log(JSON.stringify(err))),
    TE.map(offer => ({
      data: {...offer, id: offer.offerKey},
    })),
  )

const getManyOffersTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(getOneOfferTE),
    TE.map(RA.map(({data}) => data)),
    TE.map(os => ({data: [...os], total: os.length})),
  )

const OfferWithImageC = t.intersection([
  OfferBaseC,
  t.partial({offerImage: RAImageInputFieldC}),
])

export const createOfferTE = (offer: RaRecord) =>
  pipe(
    OfferWithImageC.decode(offer),
    TE.fromEither,
    TE.chainW(offerD =>
      offerD.offerImage
        ? pipe(
            getImageUrl(offerD.offerImage),
            TE.map(offerImageUri => ({...offerD, offerImageUri})),
          )
        : TE.of(offerD),
    ),
    TE.chainW(offerDWImageUri =>
      postAndDecode(OfferC)(`${currentHost}/offers`, {
        body: JSON.stringify(offerDWImageUri),
        credentials: 'include',
      }),
    ),
    TE.map(offerInserted => ({
      data: {...offerInserted, id: offerInserted.offerKey},
    })),
    TE.mapLeft(err => {
      console.log('create offer error')
      console.log(err)
      return err
    }),
  )

export const updateOfferTE = (offer: RaRecord) => {
  return pipe(
    OfferWithImageC.decode(offer),
    TE.fromEither,
    TE.chainW(offerD =>
      offerD.offerImage
        ? pipe(
            getImageUrl(offerD.offerImage),
            TE.map(offerImageUri => ({...offerD, offerImageUri})),
          )
        : TE.of(offerD),
    ),
    TE.chainW(offerDWImageUri =>
      putAndDecode(OfferC)(`${currentHost}/offers`, {
        body: JSON.stringify(offerDWImageUri),
        credentials: 'include',
      }),
    ),
    TE.map(offerUpdated => ({
      data: {...offerUpdated, id: offerUpdated.offerKey},
    })),
  )
}

const deleteOfferTE = (id: string | number) =>
  pipe(
    deleteAndDecode(t.any)(`${currentHost}/offers/${id}`, {
      credentials: 'include',
    }),
    TE.mapLeft(err => {
      console.error(err)
      return err
    }),
  )

const deleteManyOffersTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(deleteOfferTE),
    TE.map(() => ({data: [...ids]})),
  )

export const getAllStoresWithOffers = () =>
  pipe(
    getAndDecode(StoresWithOffersResponseC)(`${currentHost}/stores/list`, {
      credentials: 'include',
    }),
  )

const listOnlineOffersTE = (
  filter: Record<string, string>,
  sort: {
    [x: string]: string
  },
) =>
  pipe(
    postAndDecode(ListOnlineOffersResponseC)(
      `${currentHost}/online-offers/list`,
      {
        body: JSON.stringify({
          filter,
          sort: sortFix(sort),
        }),
        credentials: 'include',
      },
    ),
    TE.map(RA.map(offer => ({...offer, id: offer.key}))),
    TE.map(os => ({data: [...os], total: os.length})),
  )

const getOneOnlineOfferTE = (id: string | number) =>
  pipe(
    getAndDecode(OnlineOfferWKeyC)(`${currentHost}/online-offers/${id}`, {
      credentials: 'include',
    }),
    TE.mapLeft(err => console.log(JSON.stringify(err))),
    TE.map(offer => ({
      data: {...offer, id: offer.key},
    })),
  )

const getManyOnlineOffersTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(getOneOnlineOfferTE),
    TE.map(RA.map(({data}) => data)),
    TE.map(os => ({data: [...os], total: os.length})),
  )

const createOnlineOfferTE = (offer: Record<string, unknown>) =>
  pipe(
    RAImageInputFieldC.decode(offer?.logo),
    TE.fromEither,
    TE.chainW(getImageUrl),
    TE.chainW(logoUri =>
      TE.fromEither(VertOnlineOfferC.decode({...offer, logoUri})),
    ),
    TE.chainW(offerDWithImage =>
      postAndDecode(OnlineOfferWKeyC)(`${currentHost}/online-offers`, {
        body: JSON.stringify(offerDWithImage),
        credentials: 'include',
      }),
    ),
    TE.map(newOffer => ({
      data: {...newOffer, id: newOffer.key},
    })),
  )

const deleteOnlineOfferTE = (id: string | number) =>
  pipe(
    deleteAndDecode(t.any)(`${currentHost}/online-offers/${id}`, {
      credentials: 'include',
    }),
    TE.mapLeft(err => {
      console.error(err)
      return err
    }),
  )

const deleteManyOnlineOffersTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(deleteOnlineOfferTE),
    TE.map(() => ({data: [...ids]})),
  )

const updateOnlineOfferTE = (offer: RaRecord) =>
  pipe(
    RAImageInputFieldC.decode(offer?.logo),
    TE.fromEither,
    TE.chainW(getImageUrl),
    TE.orElse(() => TE.right((offer?.logoUri ?? '') as string)),
    TE.chainW(logoUri =>
      TE.fromEither(OnlineOfferWKeyC.decode({...offer, logoUri})),
    ),
    TE.chainFirstW((offer_: OnlineOfferWKey) =>
      putAndDecode(OnlineOfferWKeyC)(`${currentHost}/online-offers`, {
        body: JSON.stringify(offer_),
        credentials: 'include',
      }),
    ),
    TE.map(offer__ => ({data: {...offer__, id: offer.id}})),
  )

const listBlacklistStoresTE = (
  filter: Record<string, string>,
  sort: {
    [x: string]: string
  },
) =>
  pipe(
    postAndDecode(ListBlacklistStoresResponseC)(
      `${currentHost}/blacklist/access-store/list`,
      {
        body: JSON.stringify({
          filter,
          sort: sortFix(sort),
        }),
        credentials: 'include',
      },
    ),
    TE.map(RA.map(offer => ({...offer, id: offer.key}))),
    TE.map(os => ({data: [...os], total: os.length})),
  )

const getOneBlacklistStoreTE = (id: string | number) =>
  pipe(
    getAndDecode(BlacklistStoreWKeyC)(
      `${currentHost}/blacklist/access-store/${id}`,
      {
        credentials: 'include',
      },
    ),
    TE.mapLeft(err => console.log(JSON.stringify(err))),
    TE.map(offer => ({
      data: {...offer, id: offer.key},
    })),
  )

const getManyBlacklistStoresTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(getOneBlacklistStoreTE),
    TE.map(RA.map(({data}) => data)),
    TE.map(os => ({data: [...os], total: os.length})),
  )

const createBlacklistStoreTE = (offer: Record<string, unknown>) =>
  pipe(
    TE.fromEither(BLAccessStoreC.decode(offer)),
    TE.chainW(offerDWithImage =>
      postAndDecode(BlacklistStoreWKeyC)(
        `${currentHost}/blacklist/access-store`,
        {
          body: JSON.stringify(offerDWithImage),
          credentials: 'include',
        },
      ),
    ),
    TE.map(newStore => ({
      data: {...newStore, id: newStore.key},
    })),
  )

const deleteBlacklistStoreTE = (id: string | number) =>
  pipe(
    deleteAndDecode(t.any)(`${currentHost}/blacklist/access-store/${id}`, {
      credentials: 'include',
    }),
    TE.mapLeft(err => {
      console.error(err)
      return err
    }),
  )

const deleteManyBlacklistStoresTE = (ids: ReadonlyArray<string | number>) =>
  pipe(
    ids,
    RA.map(id => `${id}`),
    TE.traverseArray(deleteBlacklistStoreTE),
    TE.map(() => ({data: [...ids]})),
  )

const updateBlacklistStoreTE = (store: RaRecord) =>
  pipe(
    TE.fromEither(BlacklistStoreWKeyC.decode(store)),
    TE.chainFirstW((store_: BlacklistStoreWKey) =>
      putAndDecode(BlacklistStoreWKeyC)(
        `${currentHost}/blacklist/access-store`,
        {
          body: JSON.stringify(store_),
          credentials: 'include',
        },
      ),
    ),
    TE.map(store__ => ({data: {...store__, id: store.id}})),
  )

// We are forced to manually type all the props of the dataProvider
// as the provided DataProvider type is coded incorrectly and
// would require us to cast all sorts of things to `any`, which
// makes the use of TypeScript pointless.  This manual typing
// may or may not be completely right if RA is ever updated,
// but it is less wrong than the provided type.
const dataProvider = {
  getList: (
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<RaRecord>> => {
    const filter = pipe(
      params.filter,
      R.map(v => `${v}`),
    )
    const sort = {
      [params?.sort.field]: params?.sort?.order?.toLowerCase() ?? 'asc',
    }
    switch (resource) {
      case users:
        return hctaCyrt(listUsersTE(filter, sort, params?.pagination))
      case stores:
        return hctaCyrt(listStoresTE(filter, sort, params?.pagination))
      case offers:
        return hctaCyrt(listOffersTE(filter, sort))
      case onlineOffers:
        return hctaCyrt(listOnlineOffersTE(filter, sort))
      case blacklistStores:
        return hctaCyrt(listBlacklistStoresTE(filter, sort))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },

  getOne: (
    resource: string,
    {id}: {id: Identifier},
  ): Promise<GetOneResult<RaRecord>> => {
    switch (resource) {
      case users:
        return hctaCyrt(getOneUserTE(id))
      case stores:
        return hctaCyrt(getOneStoreTE(id))
      case offers:
        return hctaCyrt(getOneOfferTE(id))
      case onlineOffers:
        return hctaCyrt(getOneOnlineOfferTE(id))
      case blacklistStores:
        return hctaCyrt(getOneBlacklistStoreTE(id))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },

  getMany: (
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<RaRecord>> => {
    switch (resource) {
      case stores:
        return hctaCyrt(getManyStoresTE(params.ids))
      case offers:
        return hctaCyrt(getManyOffersTE(params.ids))
      case onlineOffers:
        return hctaCyrt(getManyOnlineOffersTE(params.ids))
      case blacklistStores:
        return hctaCyrt(getManyBlacklistStoresTE(params.ids))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },

  getManyReference: (
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<RaRecord>> => {
    switch (resource) {
      case offers:
        return hctaCyrt(listOffersTE({storeLocationKey: `${params.id}`}, {}))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },

  update: (
    resource: string,
    {id, data}: {id: Identifier; data: RaRecord},
  ): Promise<UpdateResult<RaRecord>> => {
    switch (resource) {
      case users:
        return hctaCyrt(updateUserTE(id, data))
      case stores:
        return hctaCyrt(updateStoreTE(data))
      case offers:
        return hctaCyrt(updateOfferTE(data))
      case onlineOffers:
        return hctaCyrt(updateOnlineOfferTE(data))
      case blacklistStores:
        return hctaCyrt(updateBlacklistStoreTE(data))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },
  updateMany: () => Promise.reject(),

  create: (
    resource: string,
    {data}: {data: RaRecord},
  ): Promise<CreateResult<RaRecord>> => {
    switch (resource) {
      case users:
        return hctaCyrt(createUserTE(data))
      case stores:
        return hctaCyrt(createStoreTE(data))
      case offers:
        return hctaCyrt(createOfferTE(data))
      case onlineOffers:
        return hctaCyrt(createOnlineOfferTE(data))
      case blacklistStores:
        return hctaCyrt(createBlacklistStoreTE(data))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },

  delete: (
    resource: string,
    {id}: {id: Identifier},
  ): Promise<DeleteResult<RaRecord>> => {
    switch (resource) {
      case users:
        return Promise.reject('deleting users is not supported')
      case stores:
        return hctaCyrt(deleteStoreTE(id))
      case offers:
        return hctaCyrt(deleteOfferTE(id))
      case onlineOffers:
        return hctaCyrt(deleteOnlineOfferTE(id))
      case blacklistStores:
        return hctaCyrt(deleteBlacklistStoreTE(id))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },

  deleteMany: (
    resource: string,
    {ids}: DeleteManyParams,
  ): Promise<DeleteManyResult<RaRecord>> => {
    switch (resource) {
      case users:
        return Promise.reject('deleting users is not supported')
      case stores:
        return hctaCyrt(deleteManyStoresTE(ids))
      case offers:
        return hctaCyrt(deleteManyOffersTE(ids))
      case onlineOffers:
        return hctaCyrt(deleteManyOnlineOffersTE(ids))
      case blacklistStores:
        return hctaCyrt(deleteManyBlacklistStoresTE(ids))
      default:
        return Promise.reject(`unknown resource ${resource}`)
    }
  },
}

export const addUserToAccessTravel = (userId: string, isCA = false) =>
  pipe(
    postAndDecode(AddAccessTravelUserResponse)(
      `${currentHost}/users/addToAccessTravel/${userId}?isCA=${isCA}`,
      {
        credentials: 'include',
      },
    ),
    TE.mapLeft(err => console.log(JSON.stringify(err))),
  )

export default dataProvider
