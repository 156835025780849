import {Box} from '@mui/material'
import {useState} from 'react'
import {Labeled, useRecordContext} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {LogoImage, SetImageButton} from './LogoInput'

const ImageInput: React.FC<{
  label: string
  source: string
  imageProperty?: string
  onSet?: (file: File) => void
}> = ({label, source, imageProperty, onSet}) => {
  const [image, setImage] = useState<string>('')
  const record = useRecordContext()
  const {setValue} = useFormContext()
  const handleFileUpload = (file: File | null): void => {
    if (file) {
      setValue(
        imageProperty ?? 'logo',
        {rawFile: file, title: 'logo Image'},
        {shouldDirty: true},
      )
      onSet?.(file)
      setImage(window.URL.createObjectURL(file))
    }
  }

  return (
    <Labeled label={label}>
      <Box display="flex" gap={2}>
        {image ? (
          <img src={image} alt="preview" style={{height: 120, width: 'auto'}} />
        ) : (
          record?.[source] && <LogoImage source={source} />
        )}
        <SetImageButton id={source} onSetFile={handleFileUpload} />
      </Box>
    </Labeled>
  )
}

export default ImageInput
